import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import { Content, Locales } from '../types/QueryTypes';
import GalleryPage from '../components/GalleryPage';

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    portfolio: allContent(
      filter: { slug: { glob: "/portfolio" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        slug
        title
        content {
          assets {
            title
            year
            material
            size
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
              id
            }
          }
          text {
            de {
              ...FileFragment
            }
            en {
              ...FileFragment
            }
          }
        }
      }
    }
  }

  fragment FileFragment on File {
    childMarkdownRemark {
      html
    }
  }
`;

interface DataProps {
  locales: Locales;
  portfolio?: {
    nodes?: [
      {
        id: string;
        position: number;
        slug?: string;
        title?: string;
        content?: Content;
      }
    ];
  };
}

function Portfolio({ data }: PageProps<DataProps>) {
  const sections = data.portfolio?.nodes;

  return (
    <Layout>
      <div>
        {sections?.map(
          ({ slug, id, title, content }) =>
            slug &&
            title &&
            content && (
              <GalleryPage
                slug={slug}
                id={id} // we will use this for the anchor
                title={title} // for accessibility or so
                content={content} // pass down content, handle unwrapping logic in component.
                key={id}
              />
            )
        )}
      </div>
    </Layout>
  );
}

export default Portfolio;
