import tw, { theme, styled } from 'twin.macro';

const Figure = styled.figure`
  box-shadow: ${theme`boxShadow.image-frame`};

  /* Align children */
  ${tw`inline-block text-right`}

  ${tw`rounded-md p-4`};
`;

export default Figure;
