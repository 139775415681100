import * as React from 'react';
import tw, { styled } from 'twin.macro';
import GalleryImage from './GalleryImage';
import { Images } from '../types/QueryTypes';

import { sortByYear } from '../utils';

interface GalleryProps {
  images: Images;
}

interface ContainerProps {
  readonly isFirst: boolean;
}

const Container = styled.div<ContainerProps>`
  /* ${tw`border-indigo-400 border-dashed border-2`} */

  /* handle padding, so that there is only padding in between images */
  ${(props) => (props.isFirst ? '' : tw`pl-8`)}
`;

function Gallery({ images }: GalleryProps) {
  images.sort((a, b) => sortByYear(a.year, b.year));

  return (
    <div tw="overflow-x-scroll pt-8 pb-8 justify-between grid grid-flow-col pl-4 pr-4 text-gray-600">
      {images.map((image, index) => {
        const id = image.image?.id;

        return (
          id && (
            <Container isFirst={index === 0} key={id}>
              <GalleryImage image={image} />
            </Container>
          )
        );
      })}
    </div>
  );
}

export default Gallery;
