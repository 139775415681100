import * as React from 'react';
import tw, { styled } from 'twin.macro';

type Resizable = {
  margin: number;
};

type Rect = {
  width: number;
  height: number;
};

interface FigcaptionProps {
  parent: Rect;
  image: Rect;
  children: React.ReactNode;
}

const StyledFigcaption = styled.figcaption<Resizable>`
  margin: 0 ${(props) => props.margin}px 0;

  ${tw`pt-4 text-sm text-gray-600`}
`;

function Figcaption({ parent, image, children }: FigcaptionProps) {
  const { width, height } = image;

  const displayWidth =
    width >= height ? parent.width : (parent.height / height) * width;

  const margin = (parent.width - displayWidth) / 2;

  return <StyledFigcaption margin={margin}>{children}</StyledFigcaption>;
}

export default Figcaption;
