import tw, { styled } from 'twin.macro';

const GalleryText = styled.div`
  ${tw`max-w-3xl text-gray-600`};
  h1 {
    ${tw`text-sm md:text-base lg:text-base`}
    ${tw`pb-2 md:pb-3 lg:pb-4`};
  }

  p {
    ${tw`pb-2 text-sm md:text-base lg:text-base`};
  }
`;

export default GalleryText;
