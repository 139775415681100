// eslint-disable-next-line import/prefer-default-export

export function padYear(year: string[]) {
  if (year.length !== 2) {
    return year[0];
  }

  let second = year[1];

  if (second.length === 2) {
    const century = year[0].slice(0, 2);
    second = century + second;
  }

  return second;
}

// eslint-disable-next-line import/prefer-default-export
export function sortByYear(a?: string, b?: string) {
  if (a === b) {
    return 0;
  }

  const aSplit = (a ?? '0').split('/');
  const bSplit = (b ?? '0').split('/');

  // get length of arrays
  const aCount = aSplit.length;
  const bCount = bSplit.length;

  // there must not be more than 2 years
  if (aCount > 2 || bCount > 2) {
    // keep order if something is wrong
    return 0;
  }

  // concat century years if not present
  const aYearString = padYear(aSplit);
  const bYearString = padYear(bSplit);

  // sort by last year first
  const aYear = parseInt(aYearString, 10);
  const bYear = parseInt(bYearString, 10);

  if (aYear < bYear) {
    return 1;
  }

  if (aYear > bYear) {
    return -1;
  }

  if (aYear === bYear) {
    if (aCount === 2 && bCount === 1) {
      return 1;
    }
    if (aCount === 1 && bCount === 2) {
      return -1;
    }

    if (aCount === 2 && bCount === 2) {
      const aFirst = aSplit[0];
      const bFirst = bSplit[0];
      if (aFirst < bFirst) {
        return 1;
      }
      if (aFirst > bFirst) {
        return -1;
      }
    }
  }

  return 0;
}
