import * as React from 'react';
import tw, { styled } from 'twin.macro';

import { useI18next } from 'gatsby-plugin-react-i18next';
import Gallery from './Gallery';
import { Content } from '../types/QueryTypes';
import GalleryText from './GalleryText';

const GalleryContainer = styled.div`
  ${tw`pb-12`}
`;

interface GalleryPageProps {
  slug: string;
  id: string;
  title: string;
  content: Content;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function GalleryPage({ slug, id, title, content }: GalleryPageProps) {
  const { assets: images, text } = content;
  const { language } = useI18next();

  const href = `href="${slug}#${id}"`;
  const html = text?.[language]?.childMarkdownRemark?.html?.replace(
    'href="slug#id"',
    href
  );

  return (
    <GalleryContainer>
      {/* eslint-disable-next-line react/no-danger */}
      {html && (
        <GalleryText
          id={id}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      )}
      {images && <Gallery images={images} />}
    </GalleryContainer>
  );
}

export default GalleryPage;
