import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';

import { Image } from '../types/QueryTypes';
import Figure from './Figure';
import Figcaption from './Figcaption';
import { ModalContext } from './ModalView';

const Title = tw.span`
  text-gray-600
`;

const StyledImage = tw(GatsbyImage)`
  /* Debug Border */
  /* border-black border-solid border-2 */

  w-64 h-72
  cursor-pointer
`;

interface GalleryImageProps {
  image: Image;
}

function GalleryImage({ image }: GalleryImageProps) {
  const { title, year, image: imageData } = image;
  const gatsbyImage = imageData?.childImageSharp?.gatsbyImageData;
  const displayedTitle =
    title && `${title}, ${year ?? ''}`.replace(/, $/g, '').toLowerCase();

  const imageSize = {
    width: gatsbyImage?.width ?? 0,
    height: gatsbyImage?.height ?? 0,
  };

  const container = {
    width: 256,
    height: 288,
  };

  const { setImage } = React.useContext(ModalContext);

  return (
    <Figure>
      {gatsbyImage && (
        <StyledImage
          image={gatsbyImage}
          alt={title ?? 'Image by Klaus Vogelgesang'}
          objectFit="contain"
          onClick={() => setImage(gatsbyImage)}
        />
      )}
      <Figcaption parent={container} image={imageSize}>
        {displayedTitle && <Title>{displayedTitle}</Title>}
      </Figcaption>
    </Figure>
  );
}

export default GalleryImage;
